import revive_payload_client_7shnBDhrBD from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.1_@types+node@20.12.7_@unocss+reset@0.58.9_eslint@8.57.0_floating-vue@5.2.2_rollup@_4k6x4o4gt7kzlukf4wr7jwoljm/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_S9MK91zVuh from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.1_@types+node@20.12.7_@unocss+reset@0.58.9_eslint@8.57.0_floating-vue@5.2.2_rollup@_4k6x4o4gt7kzlukf4wr7jwoljm/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_ZfND70d2nL from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.1_@types+node@20.12.7_@unocss+reset@0.58.9_eslint@8.57.0_floating-vue@5.2.2_rollup@_4k6x4o4gt7kzlukf4wr7jwoljm/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_pYPTcZg3uE from "/vercel/path0/node_modules/.pnpm/nuxt-site-config@2.2.12_@nuxt+devtools@1.0.8_@vue+compiler-core@3.4.21_nuxt@3.11.1_postcss@8._buzdjphvw5ggk2nhsmrer56u6i/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_BTRtGu4DlC from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.1_@types+node@20.12.7_@unocss+reset@0.58.9_eslint@8.57.0_floating-vue@5.2.2_rollup@_4k6x4o4gt7kzlukf4wr7jwoljm/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_Q8IUqgRZq2 from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.1_@types+node@20.12.7_@unocss+reset@0.58.9_eslint@8.57.0_floating-vue@5.2.2_rollup@_4k6x4o4gt7kzlukf4wr7jwoljm/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_s02lGUI87N from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@4.14.1_typescript@5.4.5_vue@3.4.21/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/apps/web/.nuxt/components.plugin.mjs";
import prefetch_client_rZMrDwUOzF from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.1_@types+node@20.12.7_@unocss+reset@0.58.9_eslint@8.57.0_floating-vue@5.2.2_rollup@_4k6x4o4gt7kzlukf4wr7jwoljm/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_cSwN3Kf1Bz from "/vercel/path0/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_nuxt@3.11.1_rollup@4.14.1_vue@3.4.21/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import i18n_flKSYf0oVo from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.3.0_rollup@4.14.1_vue@3.4.21/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_o9PtdxnrKC from "/vercel/path0/node_modules/.pnpm/@nuxtjs+device@3.1.1_rollup@4.14.1/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import plugin_dMQ0C1bZzc from "/vercel/path0/node_modules/.pnpm/nuxt-zod-i18n@1.6.0_rollup@4.14.1/node_modules/nuxt-zod-i18n/dist/runtime/plugin.mjs";
import chunk_reload_client_VJtVKTZlxA from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.1_@types+node@20.12.7_@unocss+reset@0.58.9_eslint@8.57.0_floating-vue@5.2.2_rollup@_4k6x4o4gt7kzlukf4wr7jwoljm/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import logger_kEXgbufg9p from "/vercel/path0/apps/web/plugins/logger.ts";
import sanity_iFCuiLdTZU from "/vercel/path0/apps/web/plugins/sanity.ts";
import sentry_client_shVUlIjFLk from "/vercel/path0/apps/web/plugins/sentry.client.ts";
import cookie_information_client_sZJQFPleMk from "/vercel/path0/apps/web/plugins/cookie-information.client.ts";
import dompurify_html_VcfsMfUvBB from "/vercel/path0/apps/web/plugins/dompurify-html.ts";
import dotlottie_player_client_Oxn7guf2ah from "/vercel/path0/apps/web/plugins/dotlottie-player.client.ts";
import feedbucket_client_bcif5LEtbp from "/vercel/path0/apps/web/plugins/feedbucket.client.ts";
export default [
  revive_payload_client_7shnBDhrBD,
  unhead_S9MK91zVuh,
  router_ZfND70d2nL,
  _0_siteConfig_pYPTcZg3uE,
  payload_client_BTRtGu4DlC,
  check_outdated_build_client_Q8IUqgRZq2,
  plugin_vue3_s02lGUI87N,
  components_plugin_KR1HBZs4kY,
  prefetch_client_rZMrDwUOzF,
  plugin_cSwN3Kf1Bz,
  i18n_flKSYf0oVo,
  plugin_o9PtdxnrKC,
  plugin_dMQ0C1bZzc,
  chunk_reload_client_VJtVKTZlxA,
  logger_kEXgbufg9p,
  sanity_iFCuiLdTZU,
  sentry_client_shVUlIjFLk,
  cookie_information_client_sZJQFPleMk,
  dompurify_html_VcfsMfUvBB,
  dotlottie_player_client_Oxn7guf2ah,
  feedbucket_client_bcif5LEtbp
]